export enum Challenges {
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
  enableAutopilot = 'specs.challenges.BMEnableAutopilot',
  enableAutopilotEditorX = 'specs.challenges.BMEnableAutopilotEditorX',
  enableDripContent = 'specs.challenges.WebMemberDripContent',
  skipMAInstallation = 'specs.programs.OOISkipMAInstallationUsers',
  enableUndoButton = 'specs.programs.OOIEnableUndoButton',
  enableCoupons = 'specs.programs.OOIEnableCoupons',
  enableCategoriesVisibility = 'specs.challenges.OOICategoriesVisibility',
}
